import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import FlexBetween from "components/FlexBetween";

const itemSchema = yup.object().shape({
  file_uploaded: yup.string().required("required"),
});

const UserAgreementUploadForm = ({ setNewUploadDocument, error }) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const token = useSelector((state) => state.token);

  const initialValuesItem = {
    file_uploaded: "",
  };

  return (
    <Formik initialValues={initialValuesItem} validationSchema={itemSchema}>
      {({ values, setFieldValue, resetForm }) => (
        <form>
          {error && <Typography color="red">Please upload a document before submitting</Typography>}
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <Box
              gridColumn="span 4"
              border={`1px solid ${palette.neutral.medium}`}
              borderRadius="5px"
              p="1rem"
            >
              <Dropzone
                acceptedFiles=".pdf,.doc"
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setFieldValue("file_uploaded", acceptedFiles[0]);
                  setNewUploadDocument({"file_uploaded": acceptedFiles[0]});
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.file_uploaded ? (
                      <p>Upload Document Here</p>
                    ) : (
                      <FlexBetween>
                        <Typography>{values.file_uploaded.name}</Typography>
                        <EditOutlinedIcon />
                      </FlexBetween>
                    )}
                  </Box>
                )}
              </Dropzone>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default UserAgreementUploadForm;

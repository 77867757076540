import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import FlexBetween from "./FlexBetween";

export default function SettingsPill({ pillTitle, pillDescription, buttonText, buttonColor, buttonAction, buttonAction2, buttonText2}) {
	return (
		<Box sx={{
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			marginY: "10px"
		}}>
			<Box>
				<Typography sx={{ fontWeight: 600 }} variant="h4" component="div">{pillTitle}</Typography>
				<Typography sx={{ fontWeight: 300 }} variant="h6" component="div">{pillDescription}</Typography>
			</Box>
			<FlexBetween>
				{buttonText && 
					<Button color={buttonColor} variant="outlined" onClick={buttonAction}>{buttonText}</Button>
				}
				{buttonText2 && 
					<Button color={buttonColor} sx={{ marginX:"2px" }} variant="outlined" onClick={buttonAction2}>{buttonText2}</Button>
				}
			</FlexBetween>
			
		</Box>
	);
}

import * as React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Grid, Autocomplete, Box, Checkbox, FormControlLabel, Slider, Switch, Stack, Typography} from "@mui/material";

export default function CreateInvoiceForm({handleChange, properties, newInvoice, add_vat, handleAddVat, handleCalculateVat, errors}) {

	return (
    <>
      <Grid
        container
        component="form"
        noValidate
        autoComplete="off"
        spacing={2}
        sx={{ marginTop: "1px" }}
      >
        <Grid item xs={12}>
          <Autocomplete
            onChange={(e, value) =>
              handleChange({
                target: { value: value.id, name: "related_occupancy" },
              })
            }
            label="Select Rental"
            fullWidth
            name="related_occupancy"
            options={properties}
            getOptionLabel={(option) =>
              `${option.related_tenant.first_name} ${option.related_tenant.last_name}-${option.related_rental_unit.related_rental.rental_name}`
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, properties) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
                key={properties.id}
              >
                {properties.related_tenant.first_name}{" "}
                {properties.related_tenant.last_name}-
                {properties.related_rental_unit.related_rental.rental_name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps }}
                label="Type Tenant or Rental Unit Name"
                error={errors.related_occupancy}
                helperText={errors.related_occupancy}
                value={
                  newInvoice.related_occupancy
                    ? newInvoice.related_occupancy ?? null
                    : ""
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            multiline
            rows={2}
            fullWidth
            name="invoice_description"
            label="Invoice Description"
            onChange={handleChange}
            value={newInvoice.invoice_description}
            error={Boolean(errors.invoice_description)}
            helperText={errors.invoice_description}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="number"
            label="Invoice Amount"
            name="invoice_amount"
            onChange={(e)=>{
              handleChange(e);
              handleCalculateVat(newInvoice.vat_percentage,e.target.value);
            }}
            value={newInvoice.invoice_amount}
            error={Boolean(errors.invoice_amount)}
            helperText={errors.invoice_amount}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Payment Terms"
            multiline
            name="payment_terms"
            rows={3}
            onChange={handleChange}
            value={newInvoice.payment_terms}
            error={Boolean(errors.payment_terms)}
            helperText={errors.payment_terms}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            value={newInvoice.has_vat}
            name="has_vat"
            control={
              <Stack direction="row" spacing={1} marginX="5px" sx={{ alignItems:"center"}}>
                <Typography>No</Typography>
                <Switch sx={{ color:"green" }}/>
                <Typography>Yes</Typography>
              </Stack>
            }
            onChange={(e) => {
              handleChange({target:{value:e.target.checked}});
              handleAddVat(e.target.checked);
              console.log(e);
            }}
            labelPlacement="start"
            label="Do you want to add VAT to the invoice?"
          />
        </Grid>
        {add_vat && (
          <>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="VAT Percentage"
                name="vat_percentage"
                onChange={(e) => {
                  handleChange(e);
                  handleCalculateVat(e.target.value, newInvoice.invoice_amount);
                }}
                value={newInvoice.vat_percentage}
                error={Boolean(errors.vat_percentage)}
                helperText={errors.vat_percentage}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                type="number"
                label="VAT Amunt"
                name="vat_amount"
                value={newInvoice.vat_amount}
                error={Boolean(errors.vat_amount)}
                helperText={errors.vat_amount}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

import * as React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import BottomCard from "components/Cards/BottomCard";
import TopCard from "components/Cards/TopCard";
import ProgressScale from "components/ProgressScale";
import DummyTable from "components/Tables/DummyTable";
import FormDialog from "components/modal";
import ReportTypography from "components/Typrographies/ReportTypography";
import { useGetRentalTax, usePropertyDashboardRevenueMonths } from "services/hooks/rentals";
import { currencyFormatter } from "utilities/currencyFormatter";
import { useSelector } from "react-redux";

export default function RentalTax() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;
    const landlordId = useSelector((state) => state.landlord);
    const {rentalTaxDetails, isLoadingRentalTaxDetails, errorRentalTaxDetails} = useGetRentalTax(1)
    const {propertyDashboardRevenueMonths, isLoadingPropertyDashboardRevenueMonths , errorPropertyDashboardRevenueMonths} = usePropertyDashboardRevenueMonths(landlordId,'landlord_id');
    
    const total_revenue = rentalTaxDetails.total_revenue;
    const sole = rentalTaxDetails.sole;
    const partnerships = rentalTaxDetails.partnerships;
    const companies = rentalTaxDetails.companies;
    const months = propertyDashboardRevenueMonths.months

	return (
            <>
            <Typography sx={{ fontWeight: 500 }} variant="h3" component="div">
                Revenue report for the year 2024
            </Typography>
            <Grid item md={12} sm={12} sx={{
                display: "flex",
                flexDirection: "row"
            }}>
                <Grid md={6} sm={12} lg={6} sx={{
                    border: "1px solid grey",
                    padding: "15px",
                    marginY: "10px",
                    marginRight: "12px",
                    borderRadius: "10px"
                }}>
                    <Typography sx={{ fontWeight: 350 }} variant="h4" component="div">
                        Revenue for the entire year
                    </Typography>
                    <Divider />
                    <Grid >
                        <TopCard cardDescription={"Total Revenue"} cardMoney={currencyFormatter(parseFloat(total_revenue))} />
                    </Grid>

                    <Grid sx={{
                        display: "flex",
                        flexDirection: "row"
                        }}>
                        <Grid md={4}>
                            <BottomCard cardTitle={"January, 2024"} cardMoney={currencyFormatter(parseFloat(months.filter((month)=>month.month==='January')[0].income))} />
                        </Grid>
                        <Grid md={4}>
                            <BottomCard cardTitle={"February, 2024"} cardMoney={currencyFormatter(parseFloat(months.filter((month)=>month.month==='February')[0].income))} />
                        </Grid>
                        <Grid md={4}>
                            <BottomCard cardTitle={"March, 2024"} cardMoney={currencyFormatter(parseFloat(months.filter((month)=>month.month==='March')[0].income))} />
                        </Grid>
                    </Grid>
                    <Grid sx={{
                        display: "flex",
                        flexDirection: "row"
                        }}>
                        <Grid md={4}>
                            <BottomCard cardTitle={"April, 2024"} cardMoney={currencyFormatter(parseFloat(months.filter((month)=>month.month==='April')[0].income))} />
                        </Grid>
                        <Grid md={4}>
                            <BottomCard cardTitle={"May, 2024"} cardMoney={currencyFormatter(parseFloat(months.filter((month)=>month.month==='May')[0].income))} />
                        </Grid>
                        <Grid md={4}>
                            <BottomCard cardTitle={"June, 2024"} cardMoney={currencyFormatter(parseFloat(months.filter((month)=>month.month==='June')[0].income))} />
                        </Grid>
                    </Grid>
                    <Grid sx={{
                        display: "flex",
                        flexDirection: "row"
                        }}>
                        <Grid md={4}>
                            <BottomCard cardTitle={"July, 2024"} cardMoney={currencyFormatter(parseFloat(months.filter((month)=>month.month==='July')[0].income))} />
                        </Grid>
                        <Grid md={4}>
                            <BottomCard cardTitle={"August, 2024"} cardMoney={currencyFormatter(parseFloat(months.filter((month)=>month.month==='August')[0].income))} />
                        </Grid>
                        <Grid md={4}>
                            <BottomCard cardTitle={"September, 2024"} cardMoney={currencyFormatter(parseFloat(months.filter((month)=>month.month==='September')[0].income))} />
                        </Grid>
                    </Grid>
                    <Grid sx={{
                        display: "flex",
                        flexDirection: "row"
                        }}>
                        <Grid md={4}>
                            <BottomCard cardTitle={"October, 2024"} cardMoney={currencyFormatter(parseFloat(months.filter((month)=>month.month==='October')[0].income))} />
                        </Grid>
                        <Grid md={4}>
                            <BottomCard cardTitle={"November, 2024"} cardMoney={currencyFormatter(parseFloat(months.filter((month)=>month.month==='November')[0].income))} />
                        </Grid>
                        <Grid md={4}>
                            <BottomCard cardTitle={"December, 2024"} cardMoney={currencyFormatter(parseFloat(months.filter((month)=>month.month==='December')[0].income))} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid md={6} sm={12} lg={6} sx={{
                    marginLeft: "12px"
                }}>
                    <Typography sx={{ fontWeight: 350 }} variant="h4" component="div">
                        Rental tax estimates for the year 2024 (To be paid once a year)
                    </Typography>
                    <Divider />
                    <Grid >
                        <TopCard cardDescription={"For Individuals"} cardMoney={currencyFormatter(parseFloat(sole))}/>
                    </Grid>
                    <ReportTypography title={"For Individuals,"} />
                    <ReportTypography title={"1. Get total (0), Subtract UGX 2,820,000"} />
                    <ReportTypography title={"2. Taxable income on your rentals will be 0"} />
                    <ReportTypography title={"3. Get 12% of the taxable income from your rentals"} />
                    <Divider />
                    <Grid >
                        <TopCard cardDescription={"For Businesses"} cardMoney={currencyFormatter(parseFloat(partnerships))} />
                    </Grid>
                    <ReportTypography title={"For Businesses,"} />
                    <ReportTypography title={"1. URA alows a up to 50% of the annal gross rental income as expeses ad losses incurred"}/>
                    <ReportTypography title={"2. Taxable income on your rentals will be 0"} />
                    <ReportTypography title={"3. Get 30% of the remaining 50% as taxable income from your rentals"} />
                    <Divider />
                </Grid>
            </Grid>
        </>
	);
}

import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import FlexBetween from "./FlexBetween";
import { FlexColCenter } from "./FlexBox/FlexBox";

export default function DocumentUploadPill({ pillTitle, pillDescription, buttonText, buttonColor, buttonAction, buttonAction2, buttonText2, imageView, imageDescription, docType}) {
	return (
		<Box sx={{
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			marginY: "10px"
		}}>
			<Box>
				<Typography sx={{ fontWeight: 600 }} variant="h4" component="div">{pillTitle}</Typography>
				<Typography sx={{ fontWeight: 300 }} variant="h6" component="div">{pillDescription}</Typography>
			</Box>
			<FlexBetween>
				{buttonText && 
					<Button color={buttonColor} variant="outlined" onClick={buttonAction}>{buttonText}</Button>
				}
				{buttonText2 && 
					<Button color={buttonColor} sx={{ marginX:"2px" }} variant="outlined" onClick={buttonAction2}>{buttonText2}</Button>
				}
				{imageView &&
					<FlexColCenter>
						{/* <img src={imageView} width='200' height='150'/> */}
						<a href={imageView} download={imageView} >Click to Download Document</a>
						<Typography sx={{ fontWeight: 300 }} variant="h6" component="div" color="green">{imageDescription}</Typography>
					</FlexColCenter>
				}
			</FlexBetween>
			
		</Box>
	);
}

import * as React from "react";
import {  useTheme, Button, Typography, Collapse } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {  formatDateString } from "utilities/date";
import { currencyFormatter } from "utilities/currencyFormatter";
import PaymentIcon from "@mui/icons-material/Payment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "services/API";
import moment from 'moment';
import { withoutSymbolFormatter } from "utilities/currencyFormatter";
import {setTenantPayment} from 'state';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#f1f1f5",
		color: "#000",
		fontSize: 15,
		fontWeight: 700,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 16,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: "white",
	},
	// // hide last border
	// "&:last-child td, &:last-child th": {
	// 	border: 0,
	// },
}));


function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

export default function TenantPaymentsTable(props) {
	const {rows, handleDeletePayment} = props
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [showDetails, setShowDetails] = React.useState(false);
	const [activeID, setActiveID] = React.useState(null);
	const location = useLocation();
	const navigate = useNavigate();
  	const dispatch = useDispatch();

	const paymentMethods = {1: 'CASH', 2: 'MOBILE MONEY', 3: 'MOMO PAY'}

	const renderStatusPills = (status) => {
		switch (status) {
			case false:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffebeb", fontSize:"10px", fontWeight:700 }} label="INCOMPLETE" size="small" color="warning" />;
			case true:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ebefff", fontSize:"10px", fontWeight:700 }} label="COMPLETE" size="small" color="primary" />;
		}
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const toggleDetailsView = (rowId) => {
		setActiveID(rowId);
		setShowDetails(!showDetails);
	};

	const handleShowReceipt = (payment) => {
		dispatch(setTenantPayment(payment));
		navigate(`/tenant-receipts`);
	};

	function DetailDropDown(props) {
		const { row, index } = props;
		const [open, setOpen] = React.useState(false);
		const [itemData, setItemData] = React.useState([]);

		const handleClickView = async (id) => {
			try {
				const response = await axios.get(`${baseURL}/tenants/completed_payments/payments_list?period_id=${id}`)
				setItemData(response.data.data);
			} catch (err) {

			}
		};

		const handleClickViewClose = async (e) => {};
		
		return (
		<>
			<StyledTableRow key={row.id}>
				<StyledTableCell align="left">{row.related_active_period.related_month.month} {row.related_active_period.related_month.year}</StyledTableCell>
				<StyledTableCell align="left">{formatDateString(row.related_active_period.date_started)}</StyledTableCell>
				<StyledTableCell align="left">{formatDateString(row.related_active_period.date_ended)}</StyledTableCell>
				<StyledTableCell component="th" scope="row">{currencyFormatter(parseInt(row.outstanding_balance))}</StyledTableCell>
				<StyledTableCell align="left">{renderStatusPills(row.is_completed)}</StyledTableCell>
				<StyledTableCell style={{ textAlign:"center" }}>
					<IconButton aria-label="expand row"  onClick={(e) => {   setOpen(!open);   handleClickView(row.related_active_period.id); }}> {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
				</StyledTableCell>
				<StyledTableCell align="left">
					<Button
						aria-controls="simple-menu"
						aria-haspopup="true"
						sx={{ color:"blue" }}
						onClick={(e) => handleShowReceipt(row)}
						>
						<PaymentIcon color="blue" sx={{ mr: 1 }}/>
						View Receipt
					</Button>
				</StyledTableCell>
			</StyledTableRow>
			<StyledTableRow key={row.id}>
			<StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
				<Collapse in={open} timeout="auto" unmountOnExit>
				<Box sx={{ margin: "10px 10px 10px 10px", width:"100%" }}>
					<Typography variant="h4" gutterBottom component="div"> Payment Details</Typography>
					{itemData ? 
						<Box sx={{ display: "flex", flexDirection: "column", margin: "15px"  }}>
							<Table stickyHeader aria-label="sticky table" maxHeight="100vh">
								<TableHead>
									<StyledTableRow>
									<StyledTableCell style={{ minWidth: 50,padding: "6px 20px" }}>Payment Date</StyledTableCell>
									<StyledTableCell style={{ minWidth: 150,padding: "6px 20px", textAlign: "center"  }}>Amount Paid</StyledTableCell>
									<StyledTableCell style={{ minWidth: 50,padding: "6px 20px", textAlign: "center"  }}>Mode of Payment</StyledTableCell>
									{/* <StyledTableCell style={{ minWidth: 50,padding: "6px 20px", textAlign: "center" , textAlign:"center" }}>Action</StyledTableCell> */}
									</StyledTableRow>
								</TableHead>
								<TableBody>
									{itemData.length === 0 ? (
									<StyledTableRow>
										<StyledTableCell colSpan={4} style={{ textAlign: "center" }}>
											<Typography variant="h5">No payments recorded</Typography>
										</StyledTableCell>
									</StyledTableRow>
									) : (
									itemData
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, index) => {
										return (
											<StyledTableRow key={row.id}>
											<StyledTableCell style={{ padding: "6px 20px", textAlign: "center"  }}>{moment(row.date_paid).format('LL')}</StyledTableCell>
											<StyledTableCell style={{ padding: "6px 20px", textAlign: "center" }}>{withoutSymbolFormatter(parseFloat(row.amount))}</StyledTableCell>
											<StyledTableCell style={{ padding: "6px 20px", textAlign: "center"  }}>{paymentMethods[row.payment_method]}</StyledTableCell>
											{/* <StyledTableCell style={{ padding: "6px 20px",textAlign: "center" }}>
												<Button
												aria-controls="simple-menu"
												aria-haspopup="true"
												onClick={(e) => {handleDeletePayment(row)}}
												>
												<PaymentIcon color="action" sx={{ mr: 1 }} />
												Delete Payment
												</Button>
											</StyledTableCell> */}
											</StyledTableRow>
										);
										})
									)}
								</TableBody>
							</Table>
						</Box> : (
							<Box sx={{ width:"100%", display:"flex", justifyContent:"center" }}>
							<h4>Payment Details Loading.......</h4>
							</Box>
						)
					}

				</Box>
				</Collapse>
			</StyledTableCell>
			</StyledTableRow>
		</>
		);
	}

	return (
		<>
			<TableContainer sx={{ display: { xs: "none", md: "block" } }} component={Paper}>
				<Table sx={{ minWidth: 700 }} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell align="left">Period Paid For</StyledTableCell>
							<StyledTableCell align="left">Month Start Date</StyledTableCell>
							<StyledTableCell align="left">Month End Date</StyledTableCell>
							<StyledTableCell>Balance</StyledTableCell>
							<StyledTableCell align="left">Is Full Payment</StyledTableCell>
                  			<StyledTableCell align="center">Payments Made</StyledTableCell>
							<StyledTableCell align="left">Actions</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
                        {(rows.length > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row, index) => (
                            <DetailDropDown row={row} index={index}/>
                        ))}
					</TableBody>
				</Table>
				<TablePagination
					component="div"
					colSpan={12}
					count={rows.length}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Rows per page" // Custom label for "Rows per page"
					sx={{
						// Styles for the TablePagination component itself
						"& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input.css-baf1rs-MuiInputBase-root-MuiTablePagination-select": {
							marginRight: "5px",
						},
						"& .MuiTablePagination-actions": {
							marginLeft: 0,
						},
						"& .MuiTablePagination-displayedRows": {
							fontSize: "1rem",
							margin: 0,
						},
						"& .MuiSelect-select.MuiInputBase-input": {
							fontSize: "1rem",
							marginTop: "10px",
						},
						"& .MuiTablePagination-selectLabel": {
							fontSize: "1rem",
						},
						"& .MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select": {
							marginTop: "4px",
						},
					}}
				/>
			</TableContainer>
		</>
	);
}

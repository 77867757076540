import * as React from "react";
import { Grid, TextField } from "@mui/material";

export default function SmartLockTemporaryPasswordCreateForm({password, passwordName, startDate, endDate}) {
  return (
    <Grid container component="form" noValidate autoComplete="off" spacing={2}>
      <Grid item xs={12} sm={12}>
        <TextField size="small" fullWidth value={password} label="Add Password" />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField size="small" fullWidth value={passwordName} label="Password Name" />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField size="small" fullWidth value={startDate} type="datetime-local" label="Start Time" />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField size="small" fullWidth value={endDate} type="datetime-local" label="End Time" />
      </Grid>
    </Grid>
  );
}

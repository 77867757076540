import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import axios from "axios";
import { baseURL } from "services/API";
import { Typography } from "@mui/material";

export default function SmartLocksPopover({ popperId, openPopper, anchorEl, handleClosePopOver, handleClickOpen, navigate, activeUnit, handleModalView, setDeleteButton, setDeleteMessage }) {
	const handleAction = async (modalview) => {
		try {
			const response = await axios.get(`${baseURL}/landlords/rentals/units/delete?unit_id=${activeUnit}`);
			if (response.data.status===200) {
				setDeleteMessage("Are you sure you want to delete this unit?");
				setDeleteButton(true);
			}

			if (response.data.status===404) {
				setDeleteMessage(
				<>
					<Typography fontWeight={"bold"} color="red">This unit can not currenty be deleted.</Typography>
					<Typography>Please remove any current tenants and try again</Typography>
				</>);
				setDeleteButton(false);
			}
		} catch (err) {
			setDeleteMessage(
			<>
				{/* <Typography fontWeight={"bold"} color="red">This actoin can not be carried out currenty..</Typography>
				<Typography>Please try again later.</Typography> */}
				<Typography fontWeight={"bold"} color="red">This unit can not currenty be deleted.</Typography>
				<Typography>Please remove any current tenants and try again</Typography>
			</>);
			setDeleteButton(false);
		}
		handleModalView(modalview);
		handleClickOpen();
		handleClosePopOver();
	};
	return (
		<div style={{ zIndex: 20000 }}>
			<Popover
				id={popperId}
				open={openPopper}
				anchorEl={anchorEl}
				onClose={handleClosePopOver}
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			>
				<Stack sx={{ padding: "10px", alignContent: "left" }}>
					<Button sx={{ color: "black", padding: "10px" }} onClick={() => handleAction("viewLogs")}>
						<RemoveRedEyeIcon sx={{ fontSize: "16px", marginRight: "5px" }} />
						View Logs
					</Button>
					<Divider />
					<Button sx={{ color: "black", padding: "10px" }} onClick={() => handleAction("tempPassword")}>
						Create Temporary Password
					</Button>
					<Divider />
					<Button sx={{ color: "black", padding: "10px" }} onClick={() => handleAction("unlockRemote")}>
						Unlock Remotely
					</Button>
				</Stack>
			</Popover>
		</div>
	);
}
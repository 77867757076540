import React, {useEffect, useState} from "react";
import { Grid, Box } from "@mui/material";
import FormDialog from "components/modal";
import DocumentUploadPill from "components/DocumentUploadPill";
import { Divider } from "@mui/material";
import IdUploadForm from "components/Forms/UserAgreementForms/idUploadForm";
import UserAgreementUploadForm from "components/Forms/UserAgreementForms/UserAgreementUploadForm";
import { baseURL, imageURL } from "services/API";
import axios from "axios";
import { useSelector } from "react-redux";
import TopSnack from "components/TopSnack";

export default function UserAgreementDocuments() {
    const landlordId = useSelector((state) => state.landlord);
    const token = useSelector((state) => state.token);
    const [openBackIdUpload, setOpenBackIdUpload] = useState(false);
    const [openFrontIdUpload, setOpenFrontIdUpload] = useState(false);
    const [openAgreementUpload, setOpenAgreementUpload] = useState(false);
    const [newUploadDocument, setNewUploadDocument] = useState(null)
	const [businessTerms, setBusinessTerms] = useState({});
    const [error, setError] = useState(false);

    const [message, setMessage] = React.useState("");
    const [openSnack, setOpenSnack] = React.useState({
        open: false,
        vertical: "top",
        horizontal: "center",
    });
    const [severity, setSeverity] = React.useState("");
    const handleCloseSnack = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpenSnack({ ...openSnack, open: false });
    };

    useEffect(()=>{
        const checkDetails = async ()=>{
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            const response = await axios.get(`${baseURL}/landlords/details/${landlordId}`);
            setBusinessTerms(response.data.business_terms);
        }
        checkDetails()
    },[])

    const handleClickOpenUploadDocument = async () => {
        let formData = new FormData
        formData.append('id', landlordId)

        try {
            const response = await axios.post(`${baseURL}/landlords/get_business_terms`, formData,
                {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    },
                }
            );
            const url = `${imageURL}${response.data.data.business_terms_document_gen}`;
            const a = document.createElement("a");
            a.href = url;
            a.download = url.split("/").pop();
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (err) {
            return
        }
	};

    const handleClickOpenBackIdUpload = () => {
		setOpenBackIdUpload(true);
	};

	const handleCloseBackIdUpload = () => {
		setOpenBackIdUpload(false);
        setNewUploadDocument(null);
        setError(false);
	};

    const handleClickOpenFrontIdUpload = () => {
		setOpenFrontIdUpload(true);
	};

	const handleCloseFrontIdUpload = () => {
		setOpenFrontIdUpload(false);
        setNewUploadDocument(null);
        setError(false);
	};

    const handleClickOpenAgreementUpload = () => {
		setOpenAgreementUpload(true);
	};

	const handleCloseAgreementUpload = () => {
		setOpenAgreementUpload(false);
        setNewUploadDocument(null);
        setError(false);
	};
  

    const handleSubmitLandlordAgreement = async () => {
        if (newUploadDocument) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

            let formData = new FormData();
            /* append input field values to formData */
            for (let value in newUploadDocument) {

            formData.append(value, newUploadDocument[value]);
            }

            try {
                const response = await axios.post(`${baseURL}/landlords/business_terms/submit?landlord_id=${landlordId}`, formData );
                setOpenAgreementUpload(false);
                setNewUploadDocument(null);
                setError(false);
                setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
                setMessage("Upload successful");
            } catch (error) {
                setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
                setMessage("Error in Uploading File");
            }
        } else {
            setError(true)
        }
    }

    const handleSubmitFrontID = async () => {
        if (newUploadDocument) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

            let formData = new FormData();
            /* append input field values to formData */
            for (let value in newUploadDocument) {

            formData.append(value, newUploadDocument[value]);
            }

            try {
                const response = await axios.post(`${baseURL}/landlords/business_terms/id_front?landlord_id=${landlordId}`, formData );
                setOpenFrontIdUpload(false);
                setNewUploadDocument(null);
                setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
                setMessage("Upload successful");
            } catch (error) {
                setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
                setMessage("Error in Uploading File");
            }
        } else {
            setError(true)
        }
    };

    const handleSubmitBackID = async () => {
        if (newUploadDocument) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

            let formData = new FormData();
            /* append input field values to formData */
            for (let value in newUploadDocument) {

            formData.append(value, newUploadDocument[value]);
            }

            try {
                const response = await axios.post(`${baseURL}/landlords/business_terms/id_back?landlord_id=${landlordId}`, formData );
                setOpenBackIdUpload(false);
                setNewUploadDocument(null);
                setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
                setMessage("Upload successful");
            } catch (error) {
                setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
                setMessage("Error in Uploading File");
            }
        } else {
            setError(true)
        }
    };


	return (
		<Box m="20px">
			<Grid container spacing={2}>
				<Grid item xs={12}>
                    <h1>User Agreement Documents</h1>
                    <h3>RENT COLLECTION SERVICE AGREEMENT</h3>
                    <Box sx={{border: "1px solid black", padding: "10px", marginBottom:"2rem"}}>
                        <DocumentUploadPill 
                            pillTitle={"TERMS OF BUSINESS"} pillDescription={"Download, Read and Sign the working terms of business document"} 
                            buttonText={"Download Document"} buttonAction={handleClickOpenUploadDocument} 
                            // buttonText={"Download Document"} buttonAction={handleClickOpenUploadDocument} 
                            buttonText2={businessTerms.business_terms_status ===1 ? "Upload Signed Document" : businessTerms.business_terms_status ===3 ? "Upload Signed Document Again" : null}  buttonAction2={handleClickOpenAgreementUpload} 
                            imageView={businessTerms.business_terms_document ? `${imageURL}${businessTerms.business_terms_document}`: null} imageDescription={businessTerms.business_terms_status===2 ? 'Document Verification is ongoing.......' : businessTerms.business_terms_status===4 ? 'Verifed and Signed Document' : null }
                        />
                        <Divider />
                    </Box>

                    <h3>NATIONAL IDENTITY CARD</h3>
                    <Box sx={{border: "1px solid black", padding: "10px", marginBottom:"2rem"}}>
                        <DocumentUploadPill 
                            pillTitle={"Front of ID Card"} pillDescription={"Attach the a photo of the FRONT part of your National ID"} 
                            buttonText={businessTerms.id_front_status  in [1,3] ? "Upload Photo" : null} buttonAction={handleClickOpenFrontIdUpload}
                            imageView={businessTerms.id_front_document ? `${imageURL}${businessTerms.id_front_document}`: null} imageDescription={businessTerms.id_front_status===2 ? 'Document Verification is ongoing.......' : businessTerms.business_terms_status===4 ? 'ID has been Verifed' : null }
                        />
                        <Divider />
                        <DocumentUploadPill 
                            pillTitle={"Back of ID Card"} pillDescription={"Attach the a photo of the BACK part of your National ID"} 
                            buttonText={businessTerms.id_back_status  in [1,3] ? "Upload Photo" : null} buttonAction={handleClickOpenBackIdUpload}
                            imageView={businessTerms.id_back_document ? `${imageURL}${businessTerms.id_back_document}`: null} imageDescription={businessTerms.id_back_status===2 ? 'Document Verification is ongoing.......' : businessTerms.business_terms_status===4 ? 'ID has been Verifed' : null }
                        />
                        <Divider />
                    </Box>
				</Grid>
			</Grid>

            <FormDialog
                open={openAgreementUpload}
                handleClose={handleCloseAgreementUpload}
                title={"Upload the signed Rent Collection Agreement"}
                handleSubmit={handleSubmitLandlordAgreement}
                buttonText="Submit Document"
                enableActions={true}
                buttonClass={"add-button"}
                content={
                    <UserAgreementUploadForm error={error} setNewUploadDocument={setNewUploadDocument}/>
                }
            />   

            <FormDialog
                open={openFrontIdUpload}
                handleClose={handleCloseFrontIdUpload}
                title={"Upload the FRONT of you National ID"}
                handleSubmit={handleSubmitFrontID}
                buttonText="Confirm Upload"
                enableActions={true}
                buttonClass={"add-button"}
                content={
                    <IdUploadForm error={error}   setNewUploadDocument={setNewUploadDocument}/>
                }
            />   

            <FormDialog
                open={openBackIdUpload}
                handleClose={handleCloseBackIdUpload}
                title={"Upload the BACK of you National ID"}
                handleSubmit={handleSubmitBackID}
                buttonText="Confirm Upload"
                enableActions={true}
                buttonClass={"add-button"}
                content={
                    <IdUploadForm error={error}  setNewUploadDocument={setNewUploadDocument}/>
                }
            />   
            <TopSnack
                vertical={openSnack.vertical}
                horizontal={openSnack.horizontal}
                open={openSnack.open}
                handleCloseSnack={handleCloseSnack}
                message={message}
                severity={severity}
            />         
		</Box>
	);
}
